import React from 'react'

const SuccessIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.7598 14L24.7098 10.5125L25.1348 5.9L20.6223 4.875L18.2598 0.875L14.0098 2.7L9.75977 0.875L7.39726 4.8625L2.88477 5.875L3.30977 10.5L0.259766 14L3.30977 17.4875L2.88477 22.1125L7.39726 23.1375L9.75977 27.125L14.0098 25.2875L18.2598 27.1125L20.6223 23.125L25.1348 22.1L24.7098 17.4875L27.7598 14ZM10.7348 19.0125L7.75976 16.0125C7.27226 15.525 7.27226 14.7375 7.75976 14.25L7.84727 14.1625C8.33477 13.675 9.13476 13.675 9.62226 14.1625L11.6348 16.1875L18.0723 9.7375C18.5598 9.25 19.3598 9.25 19.8473 9.7375L19.9348 9.825C20.4223 10.3125 20.4223 11.1 19.9348 11.5875L12.5348 19.0125C12.0223 19.5 11.2348 19.5 10.7348 19.0125Z"
        fill="#FFEBAD"
      />
    </svg>
  )
}

export default SuccessIcon
