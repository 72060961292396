import React from 'react'

const ThinPlusIcon: React.FC<any> = ({
  fill = 'var(--dls-brand-primary-color-500)',
  size = '16',
  strokeWidth = '1.6',
  ...props
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 5V19M5 12H19"
        stroke={fill}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ThinPlusIcon
