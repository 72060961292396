import React from 'react'

const AddIconCircle = ({size = '32'}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_6169_7"
        style={{maskType: 'alpha'}}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width={size}
        height={size}
      >
        <rect width="32" height="32" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_6169_7)">
        <circle cx="16" cy="16" r="12" fill="white" />
        <path
          d="M14.6667 16.6667V21C14.6667 21.2833 14.7626 21.5208 14.9543 21.7123C15.1461 21.9041 15.3837 22 15.667 22C15.9506 22 16.188 21.9041 16.3793 21.7123C16.5709 21.5208 16.6667 21.2833 16.6667 21V16.6667H21C21.2833 16.6667 21.5208 16.5708 21.7123 16.379C21.9041 16.1872 22 15.9497 22 15.6663C22 15.3828 21.9041 15.1453 21.7123 14.954C21.5208 14.7624 21.2833 14.6667 21 14.6667H16.6667V10.3333C16.6667 10.05 16.5708 9.81256 16.379 9.621C16.1872 9.42922 15.9497 9.33333 15.6663 9.33333C15.3828 9.33333 15.1453 9.42922 14.954 9.621C14.7624 9.81256 14.6667 10.05 14.6667 10.3333V14.6667H10.3333C10.05 14.6667 9.81256 14.7626 9.621 14.9543C9.42922 15.1461 9.33333 15.3837 9.33333 15.667C9.33333 15.9506 9.42922 16.188 9.621 16.3793C9.81256 16.5709 10.05 16.6667 10.3333 16.6667H14.6667ZM15.669 28.3333C13.917 28.3333 12.2702 28.0009 10.7287 27.336C9.18711 26.6711 7.84622 25.7688 6.706 24.629C5.56578 23.4892 4.663 22.1489 3.99767 20.608C3.33256 19.0671 3 17.4208 3 15.669C3 13.917 3.33244 12.2702 3.99733 10.7287C4.66222 9.18711 5.56456 7.84622 6.70433 6.706C7.84411 5.56578 9.18444 4.663 10.7253 3.99767C12.2662 3.33256 13.9126 3 15.6643 3C17.4163 3 19.0631 3.33244 20.6047 3.99733C22.1462 4.66222 23.4871 5.56456 24.6273 6.70433C25.7676 7.84411 26.6703 9.18444 27.3357 10.7253C28.0008 12.2662 28.3333 13.9126 28.3333 15.6643C28.3333 17.4163 28.0009 19.0631 27.336 20.6047C26.6711 22.1462 25.7688 23.4871 24.629 24.6273C23.4892 25.7676 22.1489 26.6703 20.608 27.3357C19.0671 28.0008 17.4208 28.3333 15.669 28.3333ZM15.6667 26.3333C18.6444 26.3333 21.1667 25.3 23.2333 23.2333C25.3 21.1667 26.3333 18.6444 26.3333 15.6667C26.3333 12.6889 25.3 10.1667 23.2333 8.1C21.1667 6.03333 18.6444 5 15.6667 5C12.6889 5 10.1667 6.03333 8.1 8.1C6.03333 10.1667 5 12.6889 5 15.6667C5 18.6444 6.03333 21.1667 8.1 23.2333C10.1667 25.3 12.6889 26.3333 15.6667 26.3333Z"
          fill="#13523B"
        />
      </g>
    </svg>
  )
}

export default AddIconCircle
