import React from 'react'

const RightArrowCircle = ({size = '48'}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_6169_11"
        style={{maskType: 'alpha'}}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="32"
        height="32"
      >
        <rect width="32" height="32" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_6169_11)">
        <circle cx="16" cy="16" r="12" fill="white" />
        <mask
          id="mask1_6169_11"
          style={{maskType: 'alpha'}}
          maskUnits="userSpaceOnUse"
          x="4"
          y="4"
          width="24"
          height="24"
        >
          <rect
            x="28"
            y="4"
            width="24"
            height="24"
            transform="rotate(90 28 4)"
            fill="#13523B"
          />
        </mask>
        <g mask="url(#mask1_6169_11)">
          <path
            d="M18.8804 15.2137L15.1051 11.3359C14.951 11.1776 14.8772 10.9929 14.8836 10.7819C14.89 10.5709 14.9703 10.3862 15.1244 10.2279C15.2785 10.0828 15.4583 10.007 15.6637 10.0004C15.8692 9.9938 16.049 10.0696 16.2031 10.2279L21.2881 15.4512C21.3652 15.5303 21.4197 15.616 21.4518 15.7084C21.4839 15.8007 21.5 15.8996 21.5 16.0051C21.5 16.1107 21.4839 16.2096 21.4518 16.3019C21.4197 16.3942 21.3652 16.48 21.2881 16.5591L16.203 21.7824C16.0618 21.9275 15.8852 22 15.6734 22C15.4615 22 15.2785 21.9275 15.1244 21.7824C14.9703 21.6241 14.8933 21.4361 14.8933 21.2185C14.8933 21.0009 14.9703 20.8129 15.1244 20.6546L18.8804 16.7965L10.2705 16.7965C10.0522 16.7965 9.86918 16.7207 9.72151 16.569C9.57384 16.4173 9.5 16.2294 9.5 16.0051C9.5 15.7809 9.57384 15.593 9.72151 15.4413C9.86918 15.2896 10.0522 15.2137 10.2705 15.2137L18.8804 15.2137Z"
            fill="#13523B"
          />
        </g>
      </g>
    </svg>
  )
}

export default RightArrowCircle
