import React from 'react'

const WarningIcon = () => {
  return (
    <svg
      width="29"
      height="26"
      viewBox="0 0 29 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.525775 19.9146L10.6615 2.02795C12.1938 -0.675983 16.0895 -0.675984 17.6217 2.02795L27.7575 19.9146C29.2685 22.5811 27.3423 25.8866 24.2774 25.8866H4.00587C0.940958 25.8866 -0.985263 22.5811 0.525775 19.9146Z"
        fill="#FFEBAD"
      />
      <path
        d="M14.251 10.2223V14.2223"
        stroke="#E86669"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <circle cx="14.2529" cy="19.2206" r="2" fill="#E86669" />
    </svg>
  )
}

export default WarningIcon
