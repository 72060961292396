import React from 'react'

interface ArrowIconProps {
  rotate?: number
  size?: number
  color?: string
}

const ArrowIcon: React.FC<ArrowIconProps> = ({
  rotate = 0,
  size = 24,
  color = 'black',
}) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height={size || '1em'}
        width={size || '1em'}
        viewBox="0 0 24 24"
        fill="currentColor"
        style={{transform: `rotate(${rotate}deg)`}}
      >
        <path
          d="M19 12H5M5 12L12 19M5 12L12 5"
          stroke={color || 'black'}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  )
}

export default ArrowIcon
