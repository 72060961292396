import React from 'react'

interface CrossIconProps {
  size?: string | number
  color?: string

  strokeWidth?: string
}

function CrossIcon({size, color, strokeWidth}: CrossIconProps) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size || 24}
        height={size || 24}
        viewBox="0 0 24 24"
        fill={color || 'currentColor'}
      >
        <path
          d="M17 7L7 17M7 7L17 17"
          stroke={color || 'black'}
          strokeWidth={strokeWidth || '1.2'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  )
}

export default CrossIcon
