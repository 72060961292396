import React from 'react'

const InternetIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="6" height="24" rx="3" fill="#FFEBAD" />
      <rect x="9" y="7.5" width="6" height="16.5" rx="3" fill="#FFEBAD" />
      <rect x="18" y="15" width="6" height="9" rx="3" fill="#FFEBAD" />
    </svg>
  )
}

export default InternetIcon
