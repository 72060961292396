import React from 'react'

interface IChevronUpProps {
  color?: string
  className?: string
}

function ChevronDown(props: IChevronUpProps) {
  const {color = '#101828', className = 'chevron-icon'} = props

  return (
    <>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        className={className}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 9.08252L12 15.0825L18 9.08252"
          stroke={color}
          style={{stroke: color, strokeOpacity: 1}}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  )
}

export default ChevronDown
