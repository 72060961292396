import React from 'react'

const LeftArrowCircle = ({size = '48'}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_90_2058"
        style={{maskType: 'alpha'}}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="32"
        height="32"
      >
        <rect width="32" height="32" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_90_2058)">
        <circle cx="16" cy="16" r="12" fill="white" />
        <mask
          id="mask1_90_2058"
          style={{maskType: 'alpha'}}
          maskUnits="userSpaceOnUse"
          x="4"
          y="4"
          width="24"
          height="24"
        >
          <rect
            x="4"
            y="28"
            width="24"
            height="24"
            transform="rotate(-90 4 28)"
            fill="#13523B"
          />
        </mask>
        <g mask="url(#mask1_90_2058)">
          <path
            d="M13.1196 16.7863L16.8949 20.6641C17.049 20.8224 17.1228 21.0071 17.1164 21.2181C17.11 21.4291 17.0297 21.6138 16.8756 21.7721C16.7215 21.9172 16.5417 21.993 16.3363 21.9996C16.1308 22.0062 15.951 21.9304 15.7969 21.7721L10.7119 16.5488C10.6348 16.4697 10.5803 16.384 10.5482 16.2916C10.5161 16.1993 10.5 16.1004 10.5 15.9949C10.5 15.8893 10.5161 15.7904 10.5482 15.6981C10.5803 15.6058 10.6348 15.52 10.7119 15.4409L15.7969 10.2176C15.9382 10.0725 16.1148 10 16.3266 10C16.5385 10 16.7215 10.0725 16.8756 10.2176C17.0297 10.3759 17.1067 10.5639 17.1067 10.7815C17.1067 10.9991 17.0297 11.1871 16.8756 11.3454L13.1196 15.2035L21.7295 15.2035C21.9478 15.2035 22.1308 15.2793 22.2785 15.431C22.4262 15.5827 22.5 15.7706 22.5 15.9949C22.5 16.2191 22.4262 16.407 22.2785 16.5587C22.1308 16.7104 21.9478 16.7863 21.7295 16.7863H13.1196Z"
            fill="#13523B"
          />
        </g>
      </g>
    </svg>
  )
}

export default LeftArrowCircle
