import React from 'react'
export interface ArrowRightprops {
  size?: number
  color?: string
}
function ArrowRight({size, color}: ArrowRightprops) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size || '24'}
        height={size || '24'}
        viewBox="0 0 24 24"
        fill={color || 'currentColor'}
      >
        <path
          d="M5 12H19M19 12L12 5M19 12L12 19"
          stroke={color || 'black'}
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  )
}

export default ArrowRight
