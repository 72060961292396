import React from 'react'

export interface CheckiconProps {
  size?: number
  color?: string
}

function CheckIcon({size, color}: CheckiconProps) {
  return (
    <svg
      width={size || '22'}
      height={size || '22'}
      viewBox="0 0 24 24"
      fill={color || 'none' || 'currentColor'}
    >
      <path
        d="M20 6L9 17L4 12"
        stroke={color || 'black'}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default CheckIcon
