import React from 'react'
export interface CircleIconProps {
  size: number
  color: string
}
function CircleIcon({size, color}: CircleIconProps) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size || '22'}
        height={size || '22'}
        viewBox="0 0 24 24"
        fill={color || 'black'}
      >
        <path
          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
          stroke={color || 'black'}
          stroke-width={size || '1.2'}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  )
}

export default CircleIcon
